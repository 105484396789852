import { render, staticRenderFns } from "./filter-desktop.vue?vue&type=template&id=8b3f2cd8"
import script from "./filter-desktop.vue?vue&type=script&lang=js"
export * from "./filter-desktop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FilterList: require('/home/d/didisher2/autodrive.spb.ru/www/components/filter/filter-list.vue').default})


  import { mapActions, mapGetters, mapMutations } from "vuex";
import filters from "@/mixins/filters";
import declension from "@/mixins/declension";

export default {
	mixins: [filters, declension],
	computed: {
		...mapGetters({
			filteredTotal: 'filters/filters/filteredTotal',
			showFilters: 'filters/filters/showFilters',
		})
	}
}
